// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faMapMarkerAlt as farFaMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons/faMapMarkerAlt";

import { faPhone as falFaPhone } from "@fortawesome/pro-light-svg-icons/faPhone";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes, farFaMapMarkerAlt);


/**
 * Add light icons
 */
library.add(falFaPhone);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
